<template>
  <div class="g_page_box">
    <g-loading :loading="loading" />
    <div class="g_main_content">
      <div class="top_card">
        <div class="card_title">
          {{data.hospital_name}} <br>
          挂号预约单
        </div>
        <div class="card_center">
          <div class="card_round"></div>
          <div class="van-hairline--top-bottom card_line"></div>
          <div class="card_round s2"></div>
        </div>
        <div class="card_bottom">
          <div class="card_info">
            <div class="card_left">
              就诊科室
            </div>
            <div class="card_right">
              {{data.department}}
            </div>
          </div>

          <div class="card_info">
            <div class="card_left">
              就诊医生
            </div>
            <div class="card_right">
              {{data.doctor_name}} [ {{data.doctor_title_label}} ]
            </div>
          </div>

          <div class="card_info">
            <div class="card_left">
              就诊时间
            </div>
            <div class="card_right">
              {{this.format_date(data.date, 'yyyy年MM月dd日')}} {{data.am_pm}}
            </div>
          </div>

          <div class="card_info">
            <div class="card_left">
              挂号费用
            </div>
            <div class="card_right">
              <span>
                {{format_money(data.price)}} 元 (专家号)
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="bottom_card">
        <van-cell title="就诊人" is-link :value="member.name" @click="choose_member" />
        <van-cell title="就诊时间" is-link :value="data.time" @click="choose_time" />
      </div>
    </div>
    <van-popup v-model="showPicker1" position="bottom" v-if="members.length">
      <van-picker
        show-toolbar
        :columns="members"
        @confirm="onConfirm1"
        @cancel="showPicker1 = false"
      />
    </van-popup>
    <van-popup v-model="showPicker2" position="bottom">
      <van-picker
        show-toolbar
        :columns="times"
        @confirm="onConfirm2"
        @cancel="showPicker12= false"
      />
    </van-popup>
    <div class="confirm_btn" @click="sub_info">提交</div>
  </div>
</template>
<script>
import { mapState } from "vuex";
import { format_date, format_money }  from '../../utils/format'
import { appointment_sub, family_info } from './service'

export default {
  data() {
    return {
      loading: false,
      member: '李可心',
      history: '初诊(没在医院就诊过)',
      showPicker1: false,
      members: [
      ],
      showPicker2: false,
      times: [
      ],
      family_members: [],
    };
  },
  computed: {
    ...mapState("appointment", {
      data: (state) => state.appointment_info,
    }),
  },
  components: {},
  watch: {},
  created() {
    this.get_member()
    this.times = []
    this.data.time_list.forEach(item=>{
      this.times.push(item.time)
    })
  },
  destroyed() {},
  methods: {
    format_date,
    format_money,
    choose_member(){
      this.showPicker1 = true
    },

    onConfirm1(value, index){
      this.member = this.family_members[index]
      this.showPicker1 = false
    },

    choose_time(){
      this.showPicker2 = true
    },

    onConfirm2(value, index){
      this.data.time = value
      this.data.row_id = this.data.time_list[index].row_id
      this.showPicker2 = false
    },
    async get_member(){
      this.loading = true
      this.members = []
      try {
        const {data} = await family_info()
        this.family_members = data.members
        this.family_members.forEach(item=>{
          if(item.default_member) {
            this.member = item
          }
          this.members.push(item.name)
        })
      }finally {
        this.loading = false
      }
    },
    async sub_info() {
      this.loading = true
      const {hospital_id,date,doctor_id,time,row_id, hospital_name} = this.data
      let prama = {
        org_id: hospital_id,
        date: date,
        doctor_id: doctor_id,
        section: time,
        row_id: row_id,
        patient_id: this.member.medical_card_no,
        hospital_name: hospital_name
      }
      try {
        const {data} = await appointment_sub(prama)
        this.$router.push({
          path: '/appointment-success',
          query: {
            id: data.id
          }
        })
      } finally {
        this.loading = false
      }
    },
  },
};
</script>
<style scoped>
.g_main_content {
  background: #f2f7f8;
  padding: 12px;
}

.top_card {
  min-height: 245px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  border-bottom: dashed 3px #f2f7f8;;
}

.card_title {
  padding: 16px 0 2px 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  color: #242938;
  text-align: center;
}

.card_center {
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow: hidden;
}

.card_round {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #f2f7f8;
  position: relative;
  margin-left: -13px;

  &.s2 {
    margin-left: 0;
    margin-right: -13px;
  }
}

.card_line {
  width: calc(100% - 30px);
  height: 4px;
}

.card_bottom {
  padding: 1px 12px 16px 12px;
}

.card_info {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 34px;
  color: #898995;
}

.card_left {
  margin-right: 24px;
}

.card_right {
  color: #242938;

  & span {
    color: #FFA700;
  }
}

.bottom_card {
  margin-top: 12px;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.02);
  border-radius: 8px;
  overflow: hidden;
}

>>>.van-cell {
  color: #898995;
}

>>>.van-cell__value {
  color: #242938;
}

.confirm_btn {
  position: fixed;
  width: calc(100% - 24px);
  height: 44px;
  background: #1890FF;
  border-radius: 22px;
  font-size: 14px;
  line-height: 44px;
  color: #FFFFFF;
  text-align: center;
  bottom: 12px;
  margin: 0 12px;
}
</style>
