import api from '../../utils/api'

// 授权
export async function appointment_sub (payload) {
  // console.log(payload)
  // return new Promise((resolve) => {
  //   setTimeout(() => {
  //     resolve({
  //       data: {
  //         id: 55
  //       }
  //     })
  //   }, 1300)
  // })
  return api.post(`/api/v1/mp/register/submit`, payload)
  //return api.post(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/orders/estimate`,payload)
}

export async function family_info (payload) {
  return api.get(`/api/v1/mp/families/relationships`, payload)
  //return api.get(`http://yapi.hitales.ai:5000/mock/393/api/v1/mp/families/relationships`,payload)
}







